import gql from "graphql-tag";

export const COURSE_COLLECTIONS = gql`
  query course_collection(
    $where: course_collection_bool_exp
    $order_by: [course_collection_order_by!]
    $limit: Int
    $offset: Int
  ) {
    course_collection(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      updated_at
      course {
        id
        created_at
        updated_at
        name
        description
        views
        img
        field {
          name
        }
        school {
          id
          name
        }
      }
      collection {
        id
        name
      }
    }
  }
`;
const COURSE_COLLECTIONS_BY_PK = gql`
  query course_collection_by_pk($id: uuid!) {
    course_collection_by_pk(id: $id) {
      id
      created_at
      updated_at
      order
      label
      course {
        id
        name
      }
      collection {
        id
        name
      }
    }
  }
`;

const COURSE_COLLECTIONS_COUNT = gql`
  query course_collection_aggregate($where: course_collection_bool_exp) {
    course_collection_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const COURSE_COLLECTIONS_AGGREGATE = gql`
  query course_collection_aggregate($where: course_collection_bool_exp) {
    course_collection_aggregate(where: $where) {
      aggregate {
        count
      }
      nodes {
        collectionId
        collection {
          id
          name
        }
      }
    }
  }
`;

const course_schema = {
  "course.collections.get.many": COURSE_COLLECTIONS,
  "course.collections.get.one": COURSE_COLLECTIONS_BY_PK,
  "course.collections.count": COURSE_COLLECTIONS_COUNT,
  "course.collections.aggregate": COURSE_COLLECTIONS_AGGREGATE,
};
export default course_schema;
