import gql from "graphql-tag";

export const FIELDS = gql`
  query field {
    field {
      id
      created_at
      updated_at
      name
    }
  }
`;

const field_schema = {
  "field.get.many": FIELDS,
};
export default field_schema;
