import { makeVar } from "@apollo/client";
export const showLoginModalVar = makeVar(false);
export const notificationVar = makeVar(null);
export const apollo_token_var = makeVar("");
export const pageVar = makeVar(0);
export const pageSizeVar = makeVar(6);


const localVariables = { showLoginModalVar, notificationVar, apollo_token_var };
export default localVariables;
