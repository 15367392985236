import user from "./user";
import client from "./client";
import course from "./course";
import slider from "./slider";
import field from "./field";
import school from "./school";
import chapter from "./chapter";
import section from "./section";
import resource from "./resource";
import collection from "./collection";
import course_collections from "./course_collections";
import course_field from "./course_field";
import course_tag from "./course_tag";
import section_resources from "./section_resources";
import instructor from "./instructor";
const schemas = {
  ...user,
  ...client,
  ...course,
  ...slider,
  ...field,
  ...school,
  ...section,
  ...chapter,
  ...resource,
  ...section_resources,
  ...collection,
  ...course_collections,
  ...course_field,
  ...course_tag,
  ...instructor,
};
export default schemas;
