import file from "./file";
import user from "./user";
import course_collection from "./course_collection";
import collection from "./collection";

const schema = {
  ...user,
  ...file,
  ...course_collection,
  ...collection
};

export default schema
