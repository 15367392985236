import React from "react";
import ClipLoader from "react-spinners/BounceLoader";

export default function Loading() {
  return (
    <div
      className="absolute h-full w-full flex items-center justify-center"
      style={{
        background: `linear-gradient(to left,#ced6e0 10%, #f1f2f6)`,
      }}
    >
      <ClipLoader size={100} color={"#2f3542"} />
    </div>
  );
}
