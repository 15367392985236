import gql from "graphql-tag";
export const INSTRUCTORS = gql`
  query instructor($where: instructor_bool_exp, $offset: Int, $limit: Int) {
    instructor(where: $where, offset: $offset, limit: $limit) {
      id
      firstName
      lastName
      profile_picture
      bio
      instructor_courses {
        course {
          id
          name
        }
      }
    }
  }
`;
const instructor_schema = {
  "instructor.get.many": INSTRUCTORS,
};
export default instructor_schema;
