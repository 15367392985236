import gql from "graphql-tag";

export const COURSE_FIELD = gql`
  query course_field(
    $where: course_field_bool_exp
    $order_by: [course_field_order_by!]
    $limit: Int
    $offset: Int
  ) {
    course_field(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      updated_at
      course {
        id
        created_at
        updated_at
        name
        description
        views
        img
        field {
          name
        }
        school {
          id
          name
        }
      }
      field {
        id
        name
      }
    }
  }
`;


const course_schema = {
  "course.field.get.many": COURSE_FIELD,
};
export default course_schema;
