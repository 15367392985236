import gql from "graphql-tag";

export const USER = gql`
  query user($where: user_bool_exp) {
    user(where: $where) {
      id
      created_at
      updated_at
      email
    }
  }
`;


const schema = {
  "user.get.one": USER,
};
export default schema;
