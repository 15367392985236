import gql from "graphql-tag";

export const COURSE_TAGS = gql`
  query course_tags($where: course_tags_bool_exp) {
    course_tags(where: $where) {
      course {
        id
        name
      }
      tag {
        name
      }
    }
  }
`;
const course_tag_schema = {
  "course.tag.get.many": COURSE_TAGS,
};
export default course_tag_schema;
