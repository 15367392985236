import gql from "graphql-tag";

export const CREATE = gql`
  mutation insert_collection_one($object: collection_insert_input!) {
    insert_collection_one(object: $object) {
      id
      created_at
      updated_at
      name
      userId
    }
  }
`;

export const DELETE = gql`
  mutation delete_collection_by_pk($id: uuid!) {
    delete_collection_by_pk(id: $id) {
      id
      created_at
      updated_at
      name
      userId
    }
  }
`;

export const UPDATE = gql`
  mutation update_collection_by_pk(
    $pk_columns: collection_pk_columns_input!
    $_set: collection_set_input
  ) {
    update_chapter_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      created_at
      updated_at
      name
      userId
    }
  }
`;

const schema = {
  "collection.create": CREATE,
  "collection.update": UPDATE,
  "collection.delete": DELETE,
};
export default schema;
