import gql from "graphql-tag";

export const COURSES = gql`
  query course($where: course_bool_exp, $offset: Int, $limit: Int) {
    course(where: $where, offset: $offset, limit: $limit) {
      id
      created_at
      updated_at
      name
      description
      views
      img
      field {
        name
      }
      school {
        id
        name
      }
    }
  }
`;
const COURSE_BY_PK = gql`
  query course_by_pk($id: uuid!) {
    course_by_pk(id: $id) {
      id
      course_instructors {
        instructor {
          firstName
          lastName
          bio
          position
          profile_picture
        }
      }
      course_tags {
        tag {
          id
          name
        }
      }
      school {
        id
        name
      }
      description
      img
      name
    }
  }
`;
const COURSE_AGGREGATE = gql`
  query course_aggregate {
    course_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const course_schema = {
  "course.get.many": COURSES,
  "course.get.one": COURSE_BY_PK,
  "course.get.count": COURSE_AGGREGATE
};
export default course_schema;
