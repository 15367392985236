import gql from "graphql-tag";

export const COLLECTIONS = gql`
  query collection($where: collection_bool_exp) {
    collection(where: $where) {
      id
      created_at
      updated_at
      name
    }
  }
`;
const COLLECTION_BY_PK = gql`
  query collection_by_pk($id: uuid!) {
    collection_by_pk(id: $id) {
      id
      created_at
      updated_at
      name
    }
  }
`;

const course_schema = {
  "collection.get.many": COLLECTIONS,
  "collection.get.one": COLLECTION_BY_PK,
};
export default course_schema;
