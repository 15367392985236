import PropTypes from "prop-types";
import { useQuery } from '@apollo/client';

import queries from "./schemas/queries";

function useQueryWrapper({ event, variables, fetchPolicy, skip, ...rest }) {
  const { loading, error, data, fetchMore } = useQuery(queries[event], {
    variables,
    fetchPolicy,
    skip,
    ...rest
  });
  // if (loading) {
  //   return "";
  // }
  if (error) return `Error, ${error.message}`;

  return { data, loading, fetchMore };
}

useQueryWrapper.propTypes = {
  event: PropTypes.string.isRequired,
};

export default useQueryWrapper;
