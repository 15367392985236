import gql from "graphql-tag";

export const INSERT_COURSE_COLLECTION_ONE = gql`
  mutation insert_course_collection_one(
    $object: course_collection_insert_input!
  ) {
    insert_course_collection_one(object: $object) {
      id
      created_at
      updated_at
      course {
        id
        name
        img
        school {
          name
        }
      }
      collection {
        id
        name
      }
    }
  }
`;

const schema = {
  "course.collection.create.one": INSERT_COURSE_COLLECTION_ONE,
};
export default schema;
