import gql from "graphql-tag";

export const INSERT_COLLECTION_ONE = gql`
  mutation insert_collection_one($object: collection_insert_input!) {
    insert_collection_one(object: $object) {
      id
      created_at
      updated_at
      name
    }
  }
`;

const schema = {
  "collection.create.one": INSERT_COLLECTION_ONE,
};
export default schema;
