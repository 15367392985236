import gql from "graphql-tag";

export const SLIDER_ITEMS = gql`
  query slider {
    slider {
      id
      created_at
      updated_at
      title
      body
      img
      link
    }
  }
`;

const course_schema = {
  "slider.get.many": SLIDER_ITEMS,
};
export default course_schema
