import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { notificationVar } from "app/services/Apollo/cache";

import mutations from "./schemas/mutations";
import queries from "./schemas/queries";

function useMutationWrapper({
  event,
  update = () => null,
  onCompleted = () => null,
}) {
  const [mutate] = useMutation(mutations[event], {
    update(cache, { data: returnedData }) {
      const updateData = update({ data: returnedData }, cache);
      if (!updateData) return;
      const { event: queryEvent, variables, data } = updateData;
      cache.writeQuery({
        query: queries[queryEvent],
        variables,
        data,
      });
    },
    onCompleted,
    onError({ graphQLErrors }) {
      notificationVar({
        message: graphQLErrors.map(
          ({ message, extensions, locations, path }) => extensions.code
        ),
        type: "danger",
        opened: true,
        __typename: "notification",
      });
    },
  });

  // if (loading) {
  //   if (event === "file.upload.one") {
  //     client.writeData({
  //       data: {
  //         uploading: true
  //       }
  //     });
  //   }
  // }
  //   if (error) return `Error, ${error.message}`;
  return { mutate };
}

useMutationWrapper.propTypes = {
  event: PropTypes.string.isRequired,
  update: PropTypes.func,
};

export default useMutationWrapper;
