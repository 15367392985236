import gql from "graphql-tag";

export const SECTION = gql`
  query section(
    $where: section_bool_exp
    $order_by: [section_order_by!]
    $limit: Int
    $offset: Int
  ) {
    section(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      updated_at
      name
      order
      description
      section_resources {
        label
        resource {
          id
          path
          type
        }
      }
    }
  }
`;
const SECTION_BY_PK = gql`
  query section_by_pk($id: uuid!) {
    section_by_pk(id: $id) {
      id
      created_at
      updated_at
      name
      order
      description
      section_resources {
        label
        resource {
          id
          path
          type
        }
      }
    }
  }
`;

const course_schema = {
  "section.get.many": SECTION,
  "section.get.one": SECTION_BY_PK,
};
export default course_schema;
