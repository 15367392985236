import gql from "graphql-tag";

export const SECTION_RESOURCES = gql`
  query section_resources(
    $where: section_resources_bool_exp
    $order_by: [section_resources_order_by!]
    $limit: Int
    $offset: Int
  ) {
    section_resources(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      updated_at
      order
      label
      resource {
        id
        path
        type
      }
      section {
        id
        name
      }
    }
  }
`;
const SECTION_RESOURCES_BY_PK = gql`
  query section_resources_by_pk($id: uuid!) {
    section_resources_by_pk(id: $id) {
      id
      created_at
      updated_at
      order
      label
      resource {
        id
        path
        type
      }
      section {
        id
        name
      }
    }
  }
`;

const course_schema = {
  "section.resources.get.many": SECTION_RESOURCES,
  "section.resources.get.one": SECTION_RESOURCES_BY_PK,
};
export default course_schema;
