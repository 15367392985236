import gql from "graphql-tag";

export const CHAPTER = gql`
  query chapter(
    $where: chapter_bool_exp
    $resource_order_by: [section_resources_order_by!]
    $limit: Int
    $offset: Int
  ) {
    chapter(
      where: $where
      order_by: { order: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      updated_at
      name
      order
      description
      course {
        id
        name
      }
      chapter_instructors {
        instructor {
          firstName
          id
          lastName
          position
          profile_picture
        }
      }
      sections(order_by: { order: asc }) {
        id
        name
        section_resources(order_by: { order: asc }) {
          label
          order
          resource {
            id
            path
            type
          }
        }
      }
    }
  }
`;
const CHAPTER_BY_PK = gql`
  query chapter_by_pk($id: uuid!) {
    chapter_by_pk(id: $id) {
      id
      created_at
      updated_at
      name
      order
      description
    }
  }
`;

const CHAPTER_AGGREGATE = gql`
  query chapter_aggregate($where: chapter_bool_exp!) {
    chapter_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const schema = {
  "chapter.get.many": CHAPTER,
  "chapter.get.one": CHAPTER_BY_PK,
  "chapter.aggregate": CHAPTER_AGGREGATE,
};
export default schema;
