import React, { useEffect, useState } from "react";
import get from "lodash/get";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useMsal } from "@azure/msal-react";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  loginRequest,
  loginSilentRequest,
} from "../../services/Auth/auth-config";
import {
  showLoginModalVar,
  notificationVar,
  apollo_token_var,
  pageVar,
  pageSizeVar,
} from "./cache";
// const msalInstance = new PublicClientApplication(msalConfig);

// const cache = new InMemoryCache();

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(
        ({ message, extensions, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extension: ${extensions.code}`
          )
        // forward(operation)
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // forward(operation);
      // window.location.reload();
    }
    forward(operation);
  }
);

const Apollo = (App) =>
  function Apolloed() {
    const { instance, inProgress } = useMsal();

    const AsyncTokenLookup = async () => {
      const accounts = await instance.getAllAccounts();

      const account = get(accounts, "[0]");
      if (account && inProgress === "none") {
        try {
          const result = await instance.acquireTokenSilent({
            ...loginSilentRequest,
            account,
          });
          return result.accessToken;
        } catch (err) {
          if (err instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(loginRequest);
          }
        }
      }
    };
    const withToken = setContext(async (_, { headers, cache }) => {
      const new_token = await AsyncTokenLookup()
      return {
        headers: {
          ...headers,
          Authorization: new_token ? `Bearer ${new_token}` : null,
        },
      };
    });

    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_BACKEND_URI,
    });

    const client = new ApolloClient({
      link: from([errorLink, withToken, httpLink]),
      connectToDevTools:true,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              // course: {
              //   keyArgs: ["where"],
              //   merge(existing = [], incoming) {
              //     return [...existing, ...incoming];
              //   },
              // },
              showLoginModal: {
                read() {
                  return showLoginModalVar();
                },
              },
              notification: {
                read() {
                  return notificationVar();
                },
              },
              page: {
                read() {
                  return pageVar();
                },
              },
              pageSize: {
                read() {
                  return pageSizeVar();
                },
              },
              apollo_token: {
                read() {
                  return apollo_token_var();
                },
              },
            },
          },
        },
      }),
    });

    // useEffect(() => {
    //   if (!!accounts[0]) {
    //     (async () => {
    //       await client.resetStore(); //clear Apollo cache when user loggs off
    //     })();
    //   }
    // }, [accounts]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    );
  };

export default Apollo;
