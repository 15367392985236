import gql from "graphql-tag";

export const DISPLAY_ALERT = gql`
  {
    displayAlert @client
    alertMessage @client
    variant @client
  }
`;

export const NOTIFICATION = gql`
  {
    notification @client {
      type
      message
      opened
    }
  }
`;

export const ALERT = gql`
  {
    alert @client {
      type
      message
      opened
    }
  }
`;

export const UPLOADING = gql`
  {
    uploading @client
  }
`;

export const USER_ENABLED = gql`
  {
    userEnabled @client
  }
`;

export const FOLDER_OWNER = gql`
  {
    folderOwner @client
  }
`;

export const FILE_DOWNLOADS = gql`
  {
    fileDownloads @client {
      id
      filename
      progress
    }
  }
`;

export const SHOW_DOWNLOAD = gql`
  {
    showDownload @client
  }
`;

export const SHARE_ID = gql`
  {
    shareId @client
  }
`;

export const SHARE_TYPE = gql`
  {
    shareType @client
  }
`;
export const SHOW_USER_SHARE_MODAL = gql`
  {
    showUserShareModal @client
  }
`;

export const SHOW_LOGIN_MODAL = gql`
  {
    showLoginModal @client
  }
`;

export const TOKEN = gql`
  {
    token @client
  }
`;

export const IS_LOGGED_IN = gql`
  {
    isLoggedIn @client
  }
`;

export const PAGE = gql`
  {
    page @client
  }
`;

export const PAGESIZE = gql`
  {
    pageSize @client
  }
`;



const schema = {
  page:PAGE,
  pageSize: PAGESIZE,
  notification: NOTIFICATION,
  uploading: UPLOADING,
  alert: ALERT,
  folderOwner: FOLDER_OWNER,
  userEnabled: USER_ENABLED,
  fileDownloads: FILE_DOWNLOADS,
  showDownload: SHOW_DOWNLOAD,
  shareId: SHARE_ID,
  shareType: SHARE_TYPE,
  showUserShareModal: SHOW_USER_SHARE_MODAL,
  token: TOKEN,
  isLoggedIn: IS_LOGGED_IN,
  showLoginModal: SHOW_LOGIN_MODAL,
};

export default schema;
