export const msalConfig = {
  auth: {
    clientId: "2dd25b9c-b65f-47fd-8510-c34f30ff0827",
    authority:
      "https://login.microsoftonline.com/39626157-a047-4689-87a2-6fa645cb5cb7",
    // redirectUri: "http://localhost:3000/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["2dd25b9c-b65f-47fd-8510-c34f30ff0827/user.read"],
  prompt: "select_account",
};

export const loginSilentRequest = {
  scopes: ["2dd25b9c-b65f-47fd-8510-c34f30ff0827/user.read"],
};