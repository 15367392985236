import gql from "graphql-tag";

export const RESOURCE = gql`
  query resource(
    $where: resource_bool_exp
    $order_by: [resource_order_by!]
    $limit: Int
    $offset: Int
  ) {
    resource(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      path
      type
    }
  }
`;

const course_schema = {
  "resource.get.many": RESOURCE,
};
export default course_schema;
