import gql from "graphql-tag";

export const SCHOOLS = gql`
  query school {
    school {
      id
      created_at
      updated_at
      name
    }
  }
`;

const school_schema = {
  "school.get.many": SCHOOLS,
};
export default school_schema;
